import "./App.css";
import { Card, Col, Container, Row } from "react-bootstrap";

function App() {
  const websitesDetails = [
    {
      title: "AR Demo Dashboard",
      link: "https://iafsmtraining-c08bd.web.app/#/",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/ar-demo.png",
    },
    {
      title: "Energy Monitoring",
      link: "http://10.17.51.12/",
      description:
        "Smart Lathe Machine Operator dashboard for monitoring the process parameters of the machine and to check the health status of the machine. The time series trends helps the operator check the real time data and react accordingly.",
      image: "images/energy_meter.jpg",
    },
    {
      title: "Lab Autonomy",
      link: "https://labautonomy.web.app/",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/autonomy.jpg",
    },
    {
      title: "Order App",
      link: "https://fsm-memento-order.web.app/",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/img.png",
    },
    {
      title: "Workstation Dashboard",
      link: "https://workstation.fsmiitd.in",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/workstation-dashboard.jpg",
    },
    {
      title: "IAFSM Live Stream",
      link: "http://fsmiitd.in:8080",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/live_stream.png",
    },
    {
      title: "MPRC Dashboard",
      link: "https://mprc.fsmiitd.in/",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/mprc_2.jpg",
    },



    {
      title: "Smart Warehouse",
      link: "https://wfp-smartwarehouse.in/",
      description: "A Smart Warehouse for Pusa Delhi grains storage.",
      image: "images/smartwarehouse.jpg",
    },

    // {
    //   title: "Lathe Operator Dashboard",
    //   link: "http://iafsm.net:8080/Thingworx/FormLogin/SmartLatheOrganisation",
    //   description:
    //     "Smart Lathe Machine Operator dashboard for monitoring the process parameters of the machine and to check the health status of the machine. The time series trends helps the operator check the real time data and react accordingly.",
    //   image: "images/operator.png",
    // },
    // {
    //   title: "Smart Shearing Machine",
    //   link: "http://iafsm.net:8080/Thingworx/FormLogin/Shearing_Portal",
    //   description:
    //     "Smart Lathe Machine Operator dashboard for monitoring the process parameters of the machine and to check the health status of the machine. The time series trends helps the operator check the real time data and react accordingly.",
    //   image: "images/shearing.jpg",
    // },
  ];

  const websitesMain = [
    {
      title: "FSM Main Site",
      link: "https://www.iafsm.in/",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/main-site.png",
    },
    {
      title: "FSM Skills",
      link: "https://www.fsmskills.in/",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque interdum ut nunc sed ultrices.",
      image: "images/fsm-skills.png",
    },
  ];
  return (
    <div className="App">
      <img src="images/fsmlogo.jpg" style={{ width: "100%" }} alt="Logo" />
      <div className="app-title">
        IITD-AIA-Foundation For Smart Manufacturing
      </div>
      <Container>
        <aside id="info-block">
          <section className="file-marker">
            <div>
              <div className="box-title">Visitor</div>
              <div className="box-contents">
                <div id="audit-trail">
                  <Row>
                    {websitesMain.map((website) => (
                      <Col
                        onClick={() => {
                          window.open(`${website.link}`);
                        }}
                        xl
                        xxl
                        lg="6"
                        md="6"
                        sm="6"
                        xs="12"
                      >
                        <Card
                          style={{ backgroundImage: `url("${website.image}")` }}
                        >
                          <div className={"back-grad"} />
                          <p className={"title-text"}>{website.title}</p>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </div>
          </section>
        </aside>
      </Container>
      <br />
      <Container>
        <aside id="info-block">
          <section className="file-marker">
            <div>
              <div className="box-title">Dashboards</div>
              <div className="box-contents-bottom">
                {websitesDetails.sort((a, b) => a.title.localeCompare(b.title)).map((website) => (
                  <Card
                    onClick={() => {
                      window.open(`${website.link}`);
                    }}
                    style={{ backgroundImage: `url("${website.image}")` }}
                  >
                    <div className={"back-grad"} />
                    <p className={"title-text"}>{website.title}</p>
                  </Card>
                ))}
              </div>
            </div>
          </section>
        </aside>
      </Container>
    </div>
  );
}

export default App;
